import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="bg-form text-center py-4 md:py-6 text-white text-base md:text-xl lg:text-2xl">
      ©Local Innovation
    </div>
  );
};

export default Footer;