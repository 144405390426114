import React from 'react';

const Value: React.FC = () => {
  const values = [
    {
      title: '賞賛しよう',
      description: '失敗は新たな一歩\n挑戦を笑わず、認め合いながら切磋琢磨しよう',
    },
    {
      title: 'いますぐやろう',
      description: '後でやる、はずっとやらない\n「やりたい」と思ったことはすぐやろう',
    },
    {
      title: 'やり方を変えてみよう',
      description: '1度やって諦めるはなし\nやり方を変えてもう1回トライしてみよう',
    },
  ];

  return (
    <div className="text-center mt-10 md:mt-20 px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold mb-8 md:mb-12 underline underline-offset-8">Value</h2>
      <div className="space-y-8 md:space-y-12">
        {values.map((value, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row items-center justify-between w-full max-w-5xl mx-auto pb-4"
          >
            <div className="text-theme font-bold text-xl md:text-3xl mb-4 md:mb-0 md:w-1/3">
              {value.title}
            </div>
            <div className="hidden md:block border-l border-theme h-16 mx-4"></div>
            <div className="md:w-2/3 text-center text-lg md:text-2xl whitespace-pre-line">
              {value.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Value;