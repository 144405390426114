import React from 'react';
import Activities1 from "../assets/activities-1.png";
import Activities2 from "../assets/activities-2.png";
import Activities3 from "../assets/activities-3.png";
import Activities4 from "../assets/activities-4.png";

const Activities: React.FC = () => {
  const activities = [
    {
      title: 'Discord交流',
      description: `DailyやTimes(分報)を運用し、Discordサーバ内での活発な交流が行われています。
                    メンバーが今やっていることや成果・進捗、困っていることなど、
                    雑多な内容を共有することで他のメンバーが手助けできる環境を作っています。`,
      imageUrl: Activities1,
      imageClass: 'w-4/5 md:w-3/4 max-w-sm',
    },
    {
      title: '勉強会',
      description: `幅広いトピックについて、その分野での経験や知識を持つGROSTメンバーを中心に勉強会を開催します。
                    また、トピックによっては企業の方をお呼びして開催することもあります。`,
      imageUrl: Activities2,
    },
    {
      title: 'LT会',
      description: `LT会とは、5~10分程度の短いプレゼンを複数人が行うイベントです。
                    GROSTでは、短時間で密度の高い技術交流が可能なLT会を開催しており、
                    メンバー間や企業の方を交えてより深く"探求"できるイベントとなっています。`,
      imageUrl: Activities3,
    },
    {
      title: 'キャリア支援',
      description: `GROSTでは専属のキャリアエージェントがおり、メンバーの探求心をキャリアへと繋げるサポートを行っています。
                    また、早期からインターンシップなどへ参加しているメンバーから直接話を聞くこともでき
                    自らのキャリア意識を高めることができます。`,
      imageUrl: Activities4,
    },
  ];

  return (
    <div id="activities" className="py-8 md:py-12 mt-10 md:mt-14 px-4 md:px-8">
      <h2 className="text-center text-2xl md:text-3xl font-bold mb-8 md:mb-10 underline underline-offset-8">活動内容</h2>
      <div className="space-y-8 md:space-y-12">
        {activities.map((activity, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center ${
              index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
            } space-y-8 md:space-y-0 md:gap-8 lg:gap-12`}
          >
            <div className="w-full md:w-1/2 order-2 md:order-none flex justify-center mt-6 md:mt-0">
              <img
                src={activity.imageUrl}
                alt={activity.title}
                className={`h-auto object-cover ${activity.imageClass || 'w-full max-w-sm'}`}
              />
            </div>

            <div className="w-full md:w-1/2 order-1 md:order-none max-w-lg text-center md:text-left mx-auto">
              <h3 className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">{activity.title}</h3>
              <p className="text-base md:text-lg lg:text-xl leading-relaxed">{activity.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activities;