import React from 'react';

const Features: React.FC = () => {
  const features = [
    {
      number: "1",
      title: "オンラインで垣根のない交流",
      description: [
        "Discordを使用し、地域や学年を超えた交流を実現しています。",
        "機能豊富なBotを活用し、技術勉強会やLT会を通じて、",
        "技術の探求を活発的に行っています。",
        "これにより、オンライン上でも活発に交流することを可能にしています。"
      ]
    },
    {
      number: "2",
      title: "幅広い技術に触れられる",
      description: [
        "モバイル、Web(フロント、バック)、ゲーム制作などの技術をはじめとして、",
        "参加者同士が知識を交換し、互いに刺激を与え合う仕組みを設けています。",
        `あなたの探求したい技術を見つけ、自分の"好き"に出会いましょう！`
      ]
    }
  ];

  return (
  <div id="features">
    <h1 className="text-center text-2xl md:text-3xl font-bold mb-6 md:mb-10 underline underline-offset-8 py-2 mt-8 md:mt-12">
      GROSTの特徴
    </h1>
    <div className="bg-gradient-to-r from-orange-400 to-amber-300 p-4 md:p-12">
      <div className="space-y-4 md:space-y-6 max-w-5xl mx-auto">
        {features.map((feature, index) => (
          <div key={index} className="bg-white/90 rounded-lg p-4 md:p-6 shadow-lg">
            <div className="flex flex-col md:flex-row items-center md:items-start gap-4 md:gap-10 h-full">
              <span className="flex justify-center items-center px-2 md:px-4 text-6xl md:text-9xl font-bold w-12 md:w-16 h-20 md:h-28 md:ml-4 bg-gradient-to-b from-gray-300 to-gray-400 text-transparent bg-clip-text leading-none">
                {feature.number}
              </span>
              <div className="flex-1">
                <h2 className="text-xl md:text-2xl font-semibold pb-2 mb-3 border-b border-black">
                  {feature.title}
                </h2>
                <div className="space-y-1 text-base md:text-2xl">
                  {feature.description.map((line, i) => (
                    <p
                      key={i}
                      className={line.includes('"好き"') || line.includes('可能') ? "text-theme" : ""}
                    >
                      {line}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Features;