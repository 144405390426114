import React from 'react';

const Mission: React.FC = () => {
  return (
    <div className="text-center mt-8 md:mt-16 mb-8 md:mb-12 px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-12 underline underline-offset-8">Mission</h2>
      <div className="text-xl md:text-3xl text-theme border border-theme py-4 md:py-6 px-4 md:px-8 lg:px-16 xl:px-48 inline-block font-bold max-w-full">
        一人ひとりの個性を引き出すポートフォリオを創る
      </div>
    </div>
  );
};

export default Mission;