import React from "react";

const About: React.FC = () => {
  return (
    <div className="text-center mt-6 md:mt-12 mb-6 md:mb-12 px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-12 underline underline-offset-8">About</h2>
      <div className="max-w-3xl mx-auto">
        <p className="text-lg md:text-2xl mb-4">
          心からワクワクしないものにはいつか飽きが来る。
        </p>
        <p className="text-lg md:text-2xl mb-4">
          挑戦しても続かない。<br />数を重ねても身につかない。<br />そして技術も自分も嫌いになる。
        </p>
        <p className="text-lg md:text-2xl mb-4">
          純粋な知的好奇心に出会うためには、いろんな分野に触れて興味を見つける。<br />
          そしてその分野の解像度を上げることで探求に繋がり、自分の興味や"好き"が見つかっていく。
        </p>
        <p className="text-lg md:text-2xl mb-4">
          GROSTは、そんな
          <span className="text-lg md:text-2xl text-theme px-2">
            寝る間も惜しんで探求したくなるような"好き"に出会えるコミュニティ 
          </span>
          です。
        </p>
      </div>
    </div>
  );
};

export default About;