import React from 'react';
import QR from "../assets/grost-qr.png";

const JoinFlow: React.FC = () => {
  const steps = [
    { number: 1, text: "QRコードからLINE友だち追加", bgColor: '#F2B077' },
    { number: 2, text: "LINEで必要事項入力", bgColor: '#F29746' },
    { number: 3, text: "Discordの招待リンクを発行", bgColor: '#F27D16' },
  ];

  return (
    <div id="entry" className="mt-10 md:mt-0 mb-8 md:mb-12 flex flex-col items-center px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold mb-8 md:mb-12 relative">
        <span className="underline underline-offset-8">入会フロー</span>
      </h2>

      <div className="flex flex-col md:flex-row gap-8 md:gap-16 max-w-2xl w-full mx-auto justify-between items-center md:items-start">
        <div className="flex justify-center items-start">
          <div className="w-40 h-40 md:w-48 md:h-48 flex-shrink-0 border-2 border-theme flex items-center justify-center">
            <img
              src={QR}
              alt="GROSTのQRコード"
              className="w-36 h-36 md:w-44 md:h-44 object-contain"
            />
          </div>
        </div>

        <div className="flex-1 w-full md:w-auto">
          <div className="relative grid justify-center">
            {steps.map((step, index) => (
              <div key={step.number} className="flex items-center mb-6 md:mb-8 relative">
                {index < steps.length - 1 && (
                  <div className="absolute top-6 md:top-10 left-4 md:left-5 w-[2px] h-[calc(100%+8px)] md:h-[calc(100%+12px)] bg-orange-400 -z-0" />
                )}
                <div
                  className="w-8 h-8 md:w-10 md:h-10 rounded-full flex items-center justify-center text-white relative z-10 text-sm md:text-base"
                  style={{ backgroundColor: step.bgColor }}
                >
                  {step.number}
                </div>
                <p className="ml-4 text-lg md:text-2xl">{step.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinFlow;
