import React from 'react';

const TopButton: React.FC = () => {
  return (
    <button
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      className="fixed bottom-8 md:bottom-12 right-4 md:right-8 w-12 h-12 md:w-16 md:h-16 bg-orange-400 rounded-full flex items-center justify-center text-white hover:bg-orange-500 transition-colors text-sm md:text-base shadow-lg"
      aria-label="ページトップへ戻る"
    >
      TOP
    </button>
  );
};

export default TopButton;