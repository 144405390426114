import React from "react";
import Header from "./components/Header";
import About from "./components/About";
import Mission from "./components/Mission";
import Value from "./components/Value";
import Features from "./components/Features";
import Activities from "./components/Activities";
import JoinRequirement from "./components/JoinRequirement";
import JoinFlow from "./components/JoinFlow";
import Question from "./components/Question";
import TopButton from "./components/TopButton";
import Footer from "./components/Footer";


function App() {
  return (
    <>
      <Header />
      <About />
      <Mission />
      <Value />
      <Features />
      <Activities />
      <JoinRequirement />
      <JoinFlow />
      <Question />
      <TopButton />
      <Footer />
    </>
  );
}

export default App;
