import React, { useState, useEffect } from 'react';
import Background from "../assets/background.png";
import Logo from "../assets/logo.png";
import LogoBlack from "../assets/logo-black.png";

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const headerHeight = (window.innerWidth < 768) ? 250 : 350;

      setIsScrolled(scrollY > headerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <div
        className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
          (isScrolled && !isMenuOpen) ? 'bg-white shadow' : 'bg-transparent'
        }`}
      >
        <div className="relative z-10 max-w-7xl mx-auto px-4">
          <nav className="flex items-center justify-between py-4">
            <div className="flex items-center space-x-2">
              <img
                src={(isScrolled || isMenuOpen) ? LogoBlack : Logo}
                alt="GROST Logo"
                className="w-32 h-10 md:w-48 md:h-16 transition-all duration-300"
              />
            </div>

            <div className="hidden md:flex items-center space-x-6">
              <a
                href="#features"
                className={`text-lg md:text-2xl transition-colors duration-300 ${
                  isScrolled ? 'text-black' : 'text-white'
                }`}
              >
                GROSTの特徴
              </a>
              <a
                href="#activities"
                className={`text-lg md:text-2xl transition-colors duration-300 ${
                  isScrolled ? 'text-black' : 'text-white'
                }`}
              >
                活動内容
              </a>
              <a
                href="#entry"
                className={`px-4 py-2 rounded-md transition-colors text-lg md:text-2xl ${
                  isScrolled ? 'bg-form text-black' : 'bg-form text-white'
                }`}
              >
                エントリー
              </a>
            </div>

            <button
              className={`md:hidden text-3xl transition-colors duration-300 ${
                (isScrolled || isMenuOpen) ? 'text-black' : 'text-white'
              }`}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              ☰
            </button>
          </nav>
        </div>
      </div>

      {isMenuOpen && (
        <div className="fixed inset-0 z-40 bg-white">
          <div className="flex flex-col items-center justify-center h-full space-y-6">
            <a
              href="#features"
              className="text-2xl"
              onClick={() => setIsMenuOpen(false)}
            >
              GROSTの特徴
            </a>
            <a
              href="#activities"
              className="text-2xl"
              onClick={() => setIsMenuOpen(false)}
            >
              活動内容
            </a>
            <a
              href="#entry"
              className="px-4 py-2 rounded-md bg-form text-black text-2xl"
              onClick={() => setIsMenuOpen(false)}
            >
              エントリー
            </a>
            <button
              className="text-3xl mt-6"
              onClick={() => setIsMenuOpen(false)}
            >
              ✕
            </button>
          </div>
        </div>
      )}

      <div
        className="relative h-[300px] md:h-[400px] flex items-center justify-center overflow-hidden"
        style={{
                  backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="relative z-10 max-w-7xl mx-auto px-4 text-center mt-16">
        <h1 className="text-center">
          <span className="text-headerGreen text-3xl md:text-5xl">"探求心"</span>
          <span className="text-white text-3xl md:text-5xl">を刺激する</span>
          <br className="md:hidden" />
          <span className="text-headerRed text-3xl md:text-5xl">"好き"</span>
          <span className="text-white text-3xl md:text-5xl">に出会える</span>
        </h1>
        <div className="flex justify-center">
          <img src={Logo} alt="GROST" className="w-64 h-20 md:w-96 md:h-32" />
        </div>
      </div>

      <div className="absolute inset-0 bg-black opacity-75"></div>
    </div>
  </div>
);
};

export default Header;
