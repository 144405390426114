import React from 'react';

const JoinRequirement: React.FC = () => {
  const requirements = [
    { text: '学生である', color: '' },
    { text: 'ITエンジニア志望である', color: '' },
    { text: '授業以外で自主的にコーディングを行っている', color: '' },
    { text: '技術を探求したい！という意欲がある', color: 'text-theme' }
  ];

  return (
    <div className="mt-10 md:mt-14 mb-8 md:mb-12 flex flex-col items-center px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-10 relative">
        <span className="underline underline-offset-8">入会条件</span>
      </h2>
      <div className="p-4 md:p-6 border-red-400 border-2 max-w-3xl w-full rounded-lg">
        <ul className="grid justify-center space-y-2 md:space-y-4">
          {requirements.map((req, index) => (
            <li key={index} className="flex items-start">
              <span className="text-lg md:text-2xl ml-2 md:ml-2 mr-2 my-1 md:my-2 font-semibold">{index + 1}.</span>
              <span className={`text-lg md:text-2xl my-1 md:my-2 font-semibold ${req.color}`}>{req.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default JoinRequirement;